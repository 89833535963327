import { update, fetchAvlQty, fetchOne, deleteOne } from "../../api";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import useRazorpay from "react-razorpay";
import { useCallback, useState, useEffect } from "react";
import moment from "moment";
import logo from "../../assets/logo.png";
import { toast } from "react-toastify";
import { KIOSK, ORDER } from "../../utils/constant";
import LoadingSpinner from "../../helper/spinner/spinner";
import { checkKioskIsOpen, logoutUser } from "../../utils/utils";

const Payment = ({
  data,
  cancel,
  order,
  brand,
  ordersTotal,
  takeaway,
  kioskId,
}) => {
  const [timeLeft, setTimeLeft] = useState(
    sessionStorage.getItem("timer") || 240
  );
  const [pause, setPause] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const Razorpay = useRazorpay();
  const [paymentType, setPaymentType] = useState("online");
  const [kioskItems, setKioskItems] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  // const packingCharges = 30;
  let navigate = useNavigate();

  useEffect(() => {
    getKiosk();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!pause) {
        if (timeLeft > 0) {
          setTimeLeft(timeLeft - 1);
          sessionStorage.setItem("timer", timeLeft);
        } else {
          navigateBack();
          return 0;
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [pause, cancel, timeLeft]);

  const getTotal = () => {
    let total = 0;
    data.forEach((e) => {
      if (e.quantity > 0) {
        const gst = (e.price * 5) / 100;
        total = total + (e.price - gst) * e.quantity;
      }
    });
    return parseFloat(total.toFixed(2));
  };

  const getGst = () => {
    let gst = 0;
    // console.log(data,'gst')
    data.forEach((e) => {
      if (e.quantity > 0) {
        const gstforItem = (e.price * 5) / 100;
        gst = gst + gstforItem * e.quantity;
      }
    });
    return parseFloat(gst.toFixed(2));
  };

  const getPackingCharges = () => {
    let packingcharge = 0;
    data.forEach((e) => {
      if (e.quantity > 0) {
        const packingChargeForItem = (e.price * 5) / 100;
        packingcharge = Math.round(
          packingcharge + packingChargeForItem * e.quantity
        );
      }
    });
    return parseFloat(packingcharge.toFixed(2));
  };

  const getFinalTotal = () => {
    let total = getTotal() + getGst();
    if (takeaway) {
      total = total + getPackingCharges();
    }
    return total;
  };

 

  const getKiosk = async () => {
    try {
      const response = await fetchAvlQty(KIOSK, "kid", kioskId);
      setKioskItems(response.data.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const navigateBack = async () => {
    setIsDisabled(true);
    try {
      const response = await deleteOne(ORDER, order._id);
      // console.log(response.data.statusCode)
      if (response.data && response.data.statusCode === 200) {
        cancel(false);
        sessionStorage.removeItem('timer')
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePayment = useCallback(
    async (kioskItems, orderDetails) => {
      const options = {
        key: "rzp_live_LyjTghj26Inwq5",
        amount: getFinalTotal() * 100,
        currency: "INR",
        name: "NIPPU KODI",
        description: "Test Transaction",
        prefill: {
          name: "Prasanth", //your customer's name
          email: "prasanth@gastronomix.life",
          contact: "9902010684",
        },
        image: logo,
        // order_id: 'order_9A33XWu170gUtm',
        handler: async (res) => {
          if (res) {
            setIsLoading(true);
            let netamout = getTotal(),
              gst = getGst(),
              payload = {
                items: data,
                brand: [brand],
                takeaway: takeaway,
                netamout,
                gst,
                date:
                  moment(new Date()).format("YYYY-MM-DDTHH:mm:ss") + ".000Z",
                totalamount: getFinalTotal(),
                token:
                  orderDetails && orderDetails.token ? orderDetails.token : "",
                status: "Payment Completed",
                deleted: false,
                kid: kioskId,
                transactionid: res.razorpay_payment_id,
              };
            const response = await update(ORDER, order._id, payload);
            if (response.data) {
              setIsLoading(false);
              navigate("/orderPlaced", {
                state: { orderId: order._id, kioskId: kioskItems._id },
              });
            }
            setIsLoading(false);
          }
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#FF2C2B",
        },
        modal: {
          ondismiss: function () {
            setPause(false);
          },
        },
      };

      const rzpay = new Razorpay(options);
      rzpay.on("payment.failed", () => {
        setIsLoading(false);
      });
      rzpay.open();
    },
    [Razorpay]
  );

  const handleCashPayment = async (kioskItems, orderDetails) => {
    setIsLoading(true);
    try {
      let netamout = getTotal(),
        gst = getGst(),
        payload = {
          items: data,
          brand: [brand],
          takeaway: takeaway,
          netamout,
          gst,
          date: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss") + ".000Z",
          totalamount: getFinalTotal(),
          token: orderDetails && orderDetails.token ? orderDetails.token : "",
          status: "Payment Pending",
          deleted: false,
          kid: kioskId,
          transactionid: "Cash_" + Math.random().toString(16).slice(2),
          transactiontype: "Cash",
        };
      const response = await update(ORDER, order._id, payload);
      if (response.data) {
        navigate("/orderPlaced", {
          state: { orderId: order._id, kioskId: kioskItems._id },
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const actualPrice = (price) => {
    const gst = (price * 5) / 100;
    return (price - gst).toFixed(2);
  };

  const payment = (
    <div className={styles.container}>
      <div>
        <div className={styles.logoContainer}>
          <div className={styles.title}>
            <span style={{ position: "absolute", right: 30 }}>{`${Math.floor(
              timeLeft / 60
            )}:${timeLeft % 60 < 10 ? "0" : ""}${timeLeft % 60}`}</span>
            <span
              onClick={() => navigateBack()}
              disabled={isDisabled}
              className={styles.backArrow}
            >
              <i className="fa fa-angle-left"></i>
            </span>
            <div>
              <span className={styles.header}>Payment Options </span>
            </div>
          </div>
        </div>
        <div className={styles.welcomeMessage}>
          Select a payment method <br />
        </div>
        <div className={styles.statusCard}>
          {data.map((e) => (
            <div key={e.skuName}>
              <div className={styles.list}>
                <div className={styles.label}>{e.skuName}</div>
                <div className={styles.value}>
                  &#x20b9; {actualPrice(e.price)}
                </div>
              </div>
              <div>
                <div className={styles.itemsLabel}>1 x {e.quantity} Items</div>
              </div>
            </div>
          ))}

          <div className={styles.listContent}>
            <div className={styles.listLabel}>
              <div className={styles.label}>Items total</div>
              <div className={styles.value}>&#x20b9; {getTotal()}</div>
            </div>

            {takeaway ? (
              <div className={styles.listLabel}>
                <div className={styles.label}>Packaging charges</div>
                <div className={styles.value}>
                  &#x20b9; {getPackingCharges()}
                </div>
              </div>
            ) : null}

            <div className={styles.listLabel}>
              <div className={styles.label}>GST 5%</div>
              <div className={styles.value}>&#x20b9; {getGst()}</div>
            </div>
          </div>
          <div className={styles.listTotal}>
            <div className={`${styles.label} ${styles.labelTotal}`}>
              Amount to Pay
            </div>
            <div className={styles.value}>&#x20b9; {getFinalTotal()}</div>
          </div>
        </div>
        <div className={`${styles.statusCard} ${styles.cardPosition}`}>
          <div className={styles.listType}>
            <div
              onClick={() => setPaymentType("online")}
              className={styles.label}
            >
              <input
                checked={paymentType === "online"}
                value="online"
                name="payment"
                type="radio"
                className={styles.radio}
              />
              <span style={{ marginLeft: 10 }}>
                Pay online (UPI/Netbanking)
              </span>
            </div>
          </div>
          <div className={styles.listType}>
            <div
              onClick={() => setPaymentType("cash")}
              className={styles.label}
            >
              <input
                checked={paymentType === "cash"}
                value="cash"
                name="payment"
                type="radio"
              />
              <span style={{ marginLeft: 10 }}>Pay Cash</span>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.buttonContainer}>
        <button
          onClick={async () => {
            if (await checkKioskIsOpen()) {
              setPause(true);
              paymentType === "online"
                ? handlePayment(kioskItems, orderDetails)
                : handleCashPayment(kioskItems, orderDetails);
            } else {
              toast.error(`Kiosk got closed!`);
              logoutUser(navigate);
            }
          }}
          className={styles.button}
          disabled={isLoading}
        >
          ₹ {getFinalTotal()} | Proceed to Pay
        </button>
      </div>
    </div>
  );

  return <>{isLoading ? <LoadingSpinner /> : payment}</>;
};
export default Payment;
