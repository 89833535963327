import logo from "../assets/logo.png";
import clock from "../assets/clock.png";
import chef from "../assets/chef_icon.png";
import styles from "./styles.module.css";
import { useMenuContext } from "../context/menuContext";

const Header = () => {
  const orders = JSON.parse(sessionStorage.getItem("orders"));
  const getWaitingTime = (data) => {
    let waitTime = 0;
    data.forEach((element) => {
      if (element.items.length) {
        waitTime += parseInt(element.items.reduce((a, v) => v.quantity + a, 0));
      }
    });
    return waitTime;
  };

  return (
    <>
      <div className={styles.logoContainer}>
        <img style={{ width: "83px" }} src={logo} alt="logo" />
      </div>
      <div className={styles.welcomeMessage}>Order Here!</div>
      <div className={styles.statusCard}>
        <div className={styles.list}>
          <div className={styles.row}>
            <img alt="icon" className={styles.icon} src={chef} />
            <div className={styles.label}>Total orders in queue</div>
          </div>
          <div className={styles.value}>
            {orders && orders.length > 0 ? orders.length : 0}
          </div>
        </div>
        <div className={styles.list}>
          <div className={styles.row}>
            <img alt="icon" className={styles.icon} src={clock} />
            <div className={styles.label}>Approximate waiting time</div>
          </div>
          <div className={styles.value}>
            {orders && orders.length > 0 ? getWaitingTime(orders) : 0} Min
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
