import { useCallback, useEffect, useState } from "react";
import styles from "./paymentStyles.module.css";
import { useMenuContext } from "../context/menuContext";
import { io } from "socket.io-client";
import useRazorpay from "react-razorpay";
import { useNavigate } from "react-router-dom";
import { deleteOne, fetchAll, fetchOne, update } from "../api";
import { BRAND, ORDER } from "../utils/constant";
import logo from "../assets/logo.png";
import moment from "moment";
import {
  checkKioskIsOpen,
  logoutUser,
  getTotal,
  getFinalTotal,
  getPackingCharges,
  getGst,
} from "../utils/utils";
import { toast } from "react-toastify";

const OrderPayment = () => {
  const order = JSON.parse(sessionStorage.getItem("order"));
  const kioskData = JSON.parse(sessionStorage.getItem("kioskData"));
  const Razorpay = useRazorpay();
  const navigate = useNavigate();
  const { state, updateState, checkItems, updateLatestData } = useMenuContext();
  const { paymentPage, isTakeAwayOrder, menuItems, paymentType } = state;
  const [timeLeft, setTimeLeft] = useState(180);
  const [orderDetails, setOrderDetails] = useState({});
  const [pauseTimer, setPauseTimer] = useState(false);
  const [brand, setBrand] = useState([]);

  const summaryData = menuItems.filter((menu) => menu.quantity > 0);
  useEffect(() => {
    const interval = setInterval(() => {
      console.log("here payment");
      console.log(pauseTimer);
      // console.log(paymentPage);
      if (!pauseTimer) {
        if (paymentPage) {
          sessionStorage.setItem("payment", true);
          // console.log("here payment");
        }
        if (timeLeft > 0) {
          setTimeLeft(timeLeft - 1);
        } else {
          if (paymentPage) {
            cancelPayment();
          }
          return 0;
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [timeLeft]);

  useEffect(() => {
    setTimeLeft(180);
  }, [paymentPage]);

  useEffect(() => {
    getOrder();
    getBrand();
  }, []);

  const getBrand = async () => {
    const res = await fetchAll(BRAND);
    // console.log(res, "res");
    if (res.data) {
      setBrand(res.data.data[0].name);
    }
  };

  const getOrder = async () => {
    try {
      const response = await fetchOne(ORDER, order._id);
      if (response.data) {
        setOrderDetails(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const actualPrice = (price) => {
    const gst = (price * 5) / 100;
    return (price - gst).toFixed(2);
  };

  const handlePayment = useCallback(async () => {
    // console.log(getFinalTotal(isTakeAwayOrder), "total");
    // updateState({ loading: true });
    const data = JSON.parse(sessionStorage.getItem("cartItems"));
    const order = JSON.parse(sessionStorage.getItem("order"));
    const options = {
      key: "rzp_live_LyjTghj26Inwq5",
      amount: getFinalTotal(isTakeAwayOrder) * 100,
      currency: "INR",
      name: "NIPPU KODI",
      description: "Live Transaction",
      prefill: {
        name: "Prasanth", //your customer's name
        email: "prasanth@gastronomix.life",
        contact: "9902010684",
      },
      image: logo,
      // order_id: 'order_9A33XWu170gUtm',
      handler: async (res) => {
        if (res) {
          // updateState({ loading: true });
          let netamout = getTotal(),
            gst = getGst(),
            payload = {
              items: data.filter((menu) => menu.quantity > 0),
              brand: ["Nippu Kodi"],
              takeaway: isTakeAwayOrder,
              netamout,
              gst,
              date: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss") + ".000Z",
              totalamount: getFinalTotal(),
              token: order && order.token ? order.token : "",
              status: "Payment Completed",
              deleted: false,
              kid: kioskData.kid,
              transactionid: res.razorpay_payment_id,
            };
          const response = await update(ORDER, order._id, payload);
          if (response.data) {
            updateLatestData();
            updateState({ loading: false, paymentPage: false });
            sessionStorage.removeItem("payment");
            navigate("/orderPlaced");
          }
          updateState({ loading: false });
        }
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#FF2C2B",
      },
      modal: {
        ondismiss: function () {
          setPauseTimer(false);
          console.log("Payment failed:");
        },
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.on("payment.failed", () => {
      updateState({ loading: false });
    });
    rzpay.open();
  }, [Razorpay]);

  const handleCashPayment = async () => {
    try {
      const data = JSON.parse(sessionStorage.getItem("cartItems"));
      const order = JSON.parse(sessionStorage.getItem("order"));
      updateState({ loading: true });
      let netamout = getTotal(),
        gst = getGst(),
        payload = {
          items: data.filter((menu) => menu.quantity > 0),
          brand: ["Nippu Kodi"],
          takeaway: isTakeAwayOrder,
          netamout,
          gst,
          date: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss") + ".000Z",
          totalamount: getFinalTotal(),
          token: order && order.token ? order.token : "",
          status: "Payment Pending",
          deleted: false,
          kid: kioskData.kid,
          transactionid: "Cash_" + Math.random().toString(16).slice(2),
          transactiontype: "Cash",
        };
      const response = await update(ORDER, order._id, payload);
      // console.log(response, "res");
      if (response.data) {
        updateLatestData();
        sessionStorage.removeItem("payment");
        updateState({ loading: false, paymentPage: false });
        navigate("/orderPlaced");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const cancelPayment = async () => {
    try {
      updateState({ loading: true });
      const response = await deleteOne(ORDER, order._id);
      if (response.data && response.data.statusCode === 200) {
        sessionStorage.removeItem("payment");
        updateState({
          paymentPage: false,
          showCheckoutPage: false,
          loading: false,
        });
        const role = sessionStorage.getItem("userType");
        if (role == "operator") {
          sessionStorage.removeItem("order");
        }

        // const socket = io("https://delectable.appswise.tech");
        // socket.emit("fetchUpdatedData");
        // socket.emit("fetchUpdatedData");
      }
    } catch (error) {
      console.log(error);
      updateState({ loading: false });
    }
  };

  return (
    <>
      {paymentPage && (
        <div className={styles.container}>
          <div>
            <div className={styles.logoContainer}>
              <div className={styles.title}>
                <span
                  style={{ position: "absolute", right: 30 }}
                >{`${Math.floor(timeLeft / 60)}:${
                  timeLeft % 60 < 10 ? "0" : ""
                }${timeLeft % 60}`}</span>
                <span
                  onClick={() => cancelPayment()}
                  //   disabled={isDisabled}
                  className={styles.backArrow}
                >
                  <i className="fa fa-angle-left"></i>
                </span>
                <span className={styles.header}>Payment Options </span>
              </div>
            </div>
            <div className={styles.welcomeMessage}>
              Select a payment method <br />
            </div>
            <div className={styles.statusCard}>
              {summaryData.map((e) => (
                <div key={e.skuName}>
                  <div className={styles.list}>
                    <div style={{ width: "65%" }} className={styles.label}>
                      {e.skuName}
                    </div>
                    <div className={styles.value}>
                      &#x20b9; {actualPrice(e.price)}
                    </div>
                  </div>
                  <div>
                    <div className={styles.itemsLabel}>
                      1 x {e.quantity} Items
                    </div>
                  </div>
                </div>
              ))}
              <br />
              <div className={styles.listContent}>
                <div className={styles.listLabel}>
                  <div className={styles.label}>Items total</div>
                  <div className={styles.value}>&#x20b9; {getTotal()}</div>
                </div>

                {/* {isTakeAwayOrder ? (
                  <div className={styles.listLabel}>
                    <div className={styles.label}>Packaging charges</div>
                    <div className={styles.value}>
                      &#x20b9; {getPackingCharges()}
                    </div>
                  </div>
                ) : null} */}

                <div className={styles.listLabel}>
                  <div className={styles.label}>GST 5%</div>
                  <div className={styles.value}>&#x20b9; {getGst()}</div>
                </div>
              </div>
              <div className={styles.listTotal}>
                <div className={`${styles.label} ${styles.labelTotal}`}>
                  Amount to Pay
                </div>
                <div className={styles.value}>&#x20b9; {getFinalTotal()}</div>
              </div>
            </div>
            <div className={`${styles.statusCard} ${styles.cardPosition}`}>
              <div className={styles.listType}>
                <div
                  onClick={() => updateState({ paymentType: "online" })}
                  className={styles.label}
                >
                  <input
                    checked={paymentType === "online"}
                    value="online"
                    name="payment"
                    type="radio"
                    className={styles.radio}
                  />
                  <span style={{ marginLeft: 10 }}>
                    Pay online (UPI/Netbanking)
                  </span>
                </div>
              </div>
              <div className={styles.listType}>
                <div
                  onClick={() => updateState({ paymentType: "cash" })}
                  className={styles.label}
                >
                  <input
                    checked={paymentType === "cash"}
                    value="cash"
                    name="payment"
                    type="radio"
                  />
                  <span style={{ marginLeft: 10 }}>Pay Cash</span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <button
              onClick={async () => {
                if (await checkKioskIsOpen()) {
                  setPauseTimer(true);
                  paymentType === "online"
                    ? handlePayment()
                    : handleCashPayment();
                } else {
                  toast.error(`Kiosk got closed!`);
                  logoutUser(navigate);
                }
              }}
              className={styles.button}
              // disabled={isLoading}
            >
              ₹ {getFinalTotal()} | Proceed to Pay
            </button>
          </div>
        </div>
      )}
    </>
  );
};
export default OrderPayment;
