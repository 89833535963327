import { useMenuContext } from "../context/menuContext";
import styles from "./styles.module.css";

const CheckOut = () => {
  const { state, updateState, blockMenuItems, checkItems } =
    useMenuContext();
  const { showCheckoutPage, isTakeAwayOrder, menuItems } = state;
  const getTotal = () => {
    return menuItems.reduce((a, v) => a + v.price * v.quantity, 0);
  };

  const getItemsCount = () => {
    return menuItems.reduce((a, v) => a + v.quantity, 0);
  };

  const checkOutContainer = (
    <div className={styles.checkoutContainerLess}>
      <div className={styles.totalDetailsLess}>
        <div className={styles.totalPrice}>&#8377;{getTotal()}</div>
        <div style={{ paddingTop: "10px" }} className={styles.totalItem}>
          Total | {getItemsCount()} items
        </div>
      </div>

      <button
        // disabled={isLoading}
        style={{ width: "44%" }}
        onClick={async () => {
          if (!(await checkItems())) {
            updateState({ showCheckoutPage: true });
          }
        }}
        className={styles.button}
      >
        Continue
      </button>
    </div>
  );

  return (
    <>
      {getItemsCount() > 0 && checkOutContainer}
      {getItemsCount() > 0 && showCheckoutPage && (
        <div className={styles.checkoutContainer}>
          <div
            onClick={() => updateState({ showCheckoutPage: false })}
            className={styles.close}
          >
            <i class="fa fa-times" aria-hidden="true"></i>
          </div>
          <div className={styles.totalDetails}>
            <div className={styles.totalItem}>
              Total | {getItemsCount()} items
            </div>
            <div className={styles.totalPrice}>&#8377;{getTotal()}</div>
          </div>
          <div className={styles.menuType}>
            <div
              onClick={() => updateState({ isTakeAwayOrder: false })}
              className={`form-check ${styles.checkboxContainer}`}
            >
              <input
                className="form-check-input"
                type="checkbox"
                checked={!isTakeAwayOrder}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                Having it here
              </label>
            </div>
            <div
              onClick={() => updateState({ isTakeAwayOrder: true })}
              className={`form-check ${styles.checkboxContainer}`}
            >
              <input
                className="form-check-input"
                type="checkbox"
                checked={isTakeAwayOrder}
              />
              <label className="form-check-label" htmlFor="flexCheckChecked">
                Take away
              </label>
            </div>
          </div>
          <button
            onClick={async () => {
              if (!(await checkItems())) {
                blockMenuItems();
              }
            }}
            className={styles.button}
          >
            Proceed to Checkout
          </button>
        </div>
      )}
    </>
  );
};
export default CheckOut;
