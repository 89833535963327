import axios from "axios"

axios.defaults.baseURL = "https://delectable.appswise.tech/api/v1"
// axios.defaults.baseURL = "http://localhost:3001/api/v1"

export const login = (userId, password) => {
  const config = {
    headers: {
      username: userId,
      password: password,
      'Content-Type': 'application/json',
    }
  }
  return axios.get("/user-management/login", config)
}
export const operatorlogin = (userId, password,type) => {
  const config = {
    headers: {
      username: userId,
      password: password,
      type: type,
      'Content-Type': 'application/json',
    }
  }
  return axios.get("/operator/login", config)
}

export const forgotPwd = (payload) => {
  return axios.post("/user-management/forgotpwd", payload)
}

export const uploadFile = (file) => {
  let payload = new FormData()
  payload.append("file", file)
  return axios.post("file-management/upload", payload)
}

export const fetchAll = (category) => {
  return axios.get(category)
}

export const fetchOne = (category, id) => {
  return axios.get(`${category}/${id}`)
}

export const create = (category, payload) => {
  return axios.post(category, payload)
}

export const update = (category, id, payload) => {
  return axios.put(`${category}/${id}`, payload)
}

export const deleteOne = (category, id) => {
  return axios.delete(`${category}/${id}`)
}

export const fetchSkuById = (skuid) => {
  return axios.get(`product/${skuid}`)
}

export const generateOtp = (number, id) => {
  return axios.get(`/user-management/otp/${number}?kid=${id}`)
}

export const submitOtp = (number, id, otp) => {
  return axios.post(`/user-management/otp/${number}?kiosk=${id}&otp=${otp}`)
}

export const fetchSales = (category, attribute, value, dateAttr, fromDate, toDate) => {
  return axios.get(`${category}/?attr=${attribute}&val=${value}&attr=${dateAttr}&startDate=${fromDate}&endDate=${toDate}`)
}

export const fetchByAttr = (category, attribute, value) => {
  return axios.get(`${category}?attr=${attribute}&val=${value}`)
}

export const fetchReportData = (
  category,
  attribute,
  value,
  dateAttr,
  fromDate,
  toDate
) => {
  return axios.get(`${category}/?attr=${attribute}&val=${value}&attr=${dateAttr}&startDate=${fromDate}&endDate=${toDate}`
  );
};

export const fetchAvlQty = (category, attribute, value) => {
  return axios.get(`${category}?attr=${attribute}&val=${value}&include=items`)
}

export const updateQty = (category, id, payload) => {
  return axios.put(`check/${category}/${id}?val=checkqty`, payload)
}
