import { useState, useEffect } from "react";
import logo from "../../assets/logo.png";
import styles from "./styles.module.css";
import { useLocation } from "react-router-dom";
import { fetchOne } from "../../api";
import { io } from "socket.io-client";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import confirmed from "../../assets/confirmed.gif";
import blast from "../../assets/blast.gif";
import LoadingSpinner from "../../helper/spinner/spinner";
import {
  checkKioskIsOpen
} from "../../utils/utils";
const OderPlaced = () => {
  const [isLoading, setIsLoading] = useState(false);
  const orderId = JSON.parse(sessionStorage.getItem("order"))._id;
  const kioskId = JSON.parse(sessionStorage.getItem("kioskData"))._id;
  const kid = JSON.parse(sessionStorage.getItem("kioskData")).kid;
  let navigate = useNavigate();
  const socket = io("https://delectable.appswise.tech");
  const [order, setOrder] = useState([]);

  useEffect(() => {
    getOrders();
  }, []);

  useEffect(() => {
    console.log("callOrderPlaced");
    socket.emit("orderPlaced");
  }, []);

  const getOrders = async () => {
    setIsLoading(true);
    try {
      const response = await fetchOne("order", orderId);
      if (response.data) {
        setOrder(response.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const navigateTo = async () => {
    const role = sessionStorage.getItem('userType')
    console.log(role)
    if (role == 'operator') {
      sessionStorage.removeItem("order")
      sessionStorage.removeItem("payment")
      if (await checkKioskIsOpen()) {
      navigate(`/menu`);
      } else {
        navigate(`/${kid}`);
      }
    } else {
      navigate(`/${kid}`);
    }
  };

  const orderPlaced = (
    <div className={styles.container}>
      {/* <div className={styles.innerCircle1}></div>
      <div className={styles.innerCircle2}></div> */}

      <div className={styles.logoContainer}>
        <img style={{ width: "83px" }} src={logo} alt="logo" />
      </div>

      <div className={styles.statusCard}>
        <div className={styles.listView}>
          <img style={{ width: 200 }} src={confirmed} alt="Done" />
          <div className={styles.itemTitle}>Great</div>
          <div className={styles.itemSubtitle}>Order Successful!</div>
        </div>
        <div className={styles.list}>
          <div className={styles.label}>Placed on</div>
          <div className={styles.value}>
            {moment(new Date()).format("DD/MM/YYYY")}
          </div>
        </div>
        <div className={styles.list}>
          <div className={styles.label}>Order ID</div>
          <div className={styles.value}>
            #{order && order.oid ? order.oid : 0}
          </div>
        </div>
        <div className={styles.list}>
          <div className={styles.label}>Outlet ID</div>
          <div className={styles.value}>
            {kid}
          </div>
        </div>
        <div className={styles.center}>
          <div className={styles.dottedBorder}></div>

          <div className={styles.tokenCard}>
            {/* <div className={`${styles.token} ${styles.semiCircleOne}`}></div>
            <div className={`${styles.token} ${styles.semiCircleTwo}`}></div>
            <div className={`${styles.token} ${styles.semiCircleThree}`}></div>
            <div className={`${styles.token} ${styles.semiCircleFour}`}></div> */}
            <div className={styles.tokenTitle}>
              {order && order.token ? order.token : 0}
            </div>
            <div className={styles.subtitle}>Token number</div>
          </div>

          <div className={styles.tokenText}>
            <div
              style={{ width: "90%", textAlign: "center", marginBottom: 20 }}
            >
              Your token number will be called out once your order is ready.
              Please show this page to collect your order.
            </div>
          </div>
          <img
            style={{ position: "absolute", width: 300 }}
            src={blast}
            alt="Done"
          />
        </div>
      </div>

      <div onClick={() => navigateTo()} className={styles.orderAgain}>
        Order Again
      </div>
    </div>
  );
  return <>{isLoading ? <LoadingSpinner /> : orderPlaced}</>;
};
export default OderPlaced;
