import { useCallback, useEffect, useState } from "react";
import { useMenuContext } from "../context/menuContext";
import MenuCard from "./menuCard";
const MenuList = () => {
  const { menuItems } = useMenuContext().state;
  const [sortedMenuItems, setsortedMenuItems] = useState([]);
 
  useEffect(() => {
    const removeParatha = menuItems.filter(e => e.price > 0)
    const outStock = removeParatha.filter(e => e.stock == 0).sort((a, b) => a.price - b.price)
    const inStock = removeParatha.filter(e => e.stock >=1).sort((a, b) => a.price - b.price)
    const sorteditems = inStock.concat(outStock)
    setsortedMenuItems(sorteditems)
  }, );
  return (
    <>
      {sortedMenuItems.map((item,index) => (
        <MenuCard key={item._id} item={item} index={index} />
      ))}
    </>
  );
};

export default MenuList;
