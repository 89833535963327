import styles from "./styles.module.css";
import logo from "../../assets/logo.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchByAttr, generateOtp } from "../../api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Login = () => {
  let navigate = useNavigate();
  let [number, setNumber] = useState("");
  // let [userType, setType] = useState("");
  const [loading, setLoading] = useState(false)
  let state = useLocation(),
    { kiosk, partner } = state.state;



  let submitNumber = async () => {
    setLoading(true)
    let response = await fetchByAttr('operator', 'phone', number);
    if (response.data.data != null && response.data.statusCode === 200) {
      // setType(response.data.data[0].user)
      let userType = response.data.data[0].user
      setLoading(false)
      toast.success(`Please Enter Your password`);
      navigate("otp", { state: { kiosk, partner, number, userType } });
    } else {
      let response = await generateOtp(number, kiosk.kid);
      if (response.data.statusCode === 200) {
        let userType = 'user'
        // setType(tp)
        // console.log(ty)
        setLoading(false)
        toast.success(response.data.message);
        navigate("otp", { state: { kiosk, partner, number, userType } });
      }
    }
  };

  let handleNumberChange = (event) => {
    setNumber(event.target.value);
  };

  const validate = () => {
    if (!number) {
      return true;
    }
    const mobileRegex = new RegExp(/^([+]\d{2})?\d{10}$/);
    if (!mobileRegex.test(number)) {
      return true;
    }
  };
  return (
    <>
      <div className="background"></div>
      <div className={styles.loginContainer}>
        <img src={logo} alt="logo" />
        <div className={styles.welcomeMessage}>
          Welcome! <br />
          <div className={styles.caption}>
            <span>Outlet ID {kiosk.kid}</span>
          </div>
        </div>
        <div className={styles.sub_caption}>
          <span>Please authenticate your mobile number to start ordering</span>
        </div>
        <div>
          <input
            className={styles.input}
            placeholder="Enter your mobile number"
            value={number}
            onChange={handleNumberChange}
          />
        </div>
        <button
          disabled={validate() || loading}
          className={styles.button}
          onClick={() => submitNumber()}
        >
          {loading ? <span><span className="fa fa-gear"></span><span style={{ marginLeft: 10 }}>Generate OTP</span></span> : <span> Generate OTP</span>}

        </button>
        {/* <button onClick={() => submitNumber()} className={styles.resendBtn}>
          Resend OTP
        </button> */}
      </div>
    </>
  );
};
export default Login;
