import styles from "./styles.module.css";
import gLogo from "../../assets/g-logo.png";
import chefImage from "../../assets/chef.png";
import { useNavigate, useParams } from "react-router-dom";
import { fetchOne, fetchByAttr } from "../../api";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import moment from "moment";

const Welcome = () => {
  let navigate = useNavigate();
  let [kiosk, setKiosk] = useState({});
  let [partner, setPartner] = useState({});
  const [partnerActive, setPartnerActive] = useState(null);
  let { id } = useParams();

  useEffect(() => {
    sessionStorage.clear();
  }, []);
  let getKiosk = async () => {
    let response = await fetchOne("kiosk", id);

    try {
      sessionStorage.setItem("kioskData", JSON.stringify(response.data.data));
      sessionStorage.setItem("kioskId", id);
      setKiosk(response.data.data);
      getPartner(response.data.data.opid);
    } catch (ex) {
      console.log(ex);
    }
  };
  let getPartner = async (partnerid) => {
    let response = await fetchByAttr("operator", "opid", partnerid);
    try {
      let data = response.data.data[0];
      setPartner(data);
      if (
        data.lastlogin &&
        moment(data.lastlogin).isSame(moment(new Date()), "day") &&
        data.loggedIn
      ) {
        sessionStorage.setItem("partnerData", JSON.stringify(data));
        setPartnerActive(true);
      }
    } catch (ex) {
      console.log(ex);
    }
  };
  React.useEffect(() => {
    if (id) {
      getKiosk(id);
    }
  }, [id]);

  return (
    <>
      <div className="background"></div>
      <div className={styles.loginContainer}>
        <motion.div
          initial={{ transform: "translate(0,165%) scale(1.3)", zIndex: 100 }}
          animate={{ transform: "translate(0,0%) scale(1)" }}
          transition={{ delay: 1.5, duration: 1 }}
        >
          <div className={styles.logoContainer}></div>
        </motion.div>

        {partnerActive ? (
          <motion.div
            initial={{ transform: "translate(0,65%)" }}
            animate={{ transform: "translate(0,0%)" }}
            transition={{ delay: 1.5, duration: 1 }}
          >
            <div className={styles.circle1}>
              <div className={styles.circle2}>
                <div className={styles.circle3}>
                  <motion.div
                    initial={{ transform: "scale(0)" }}
                    animate={{ transform: "scale(1)" }}
                    transition={{ delay: 2.5, duration: 1 }}
                    className={styles.circle4}
                    style={{
                      backgroundImage:
                        partner && partner.photo
                          ? "url(" + `${partner.photo}` + ")"
                          : "url(" + chefImage + ")",
                    }}
                  ></motion.div>
                </div>
              </div>
            </div>
          </motion.div>
        ) : (
          <div className={styles.inActivePartner}></div>
        )}

        {partnerActive ? (
          <>
            <motion.div
              initial={{ transform: "scale(0)" }}
              animate={{ transform: "scale(1)" }}
              transition={{ delay: 2.7, duration: 1 }}
              className={styles.caption}
            >
              <span>Today you are being served by</span>
            </motion.div>
            <motion.div
              initial={{ transform: "scale(0)" }}
              animate={{ transform: "scale(1)" }}
              transition={{ delay: 2.7, duration: 1 }}
              className={styles.name}
            >
              {partner && partner.firstname ? (
                `${partner.firstname} ${partner.lastname}`
              ) : (
                <span className="fa fa-gear"></span>
              )}
            </motion.div>
            <motion.div
              initial={{ display: "none", transform: "translate(0,600px)" }}
              animate={{ display: "block", transform: "translate(0,0px)" }}
              transition={{ delay: 2.7, duration: 1 }}
              className={styles.welcomeMessage}
            >
              We are glad <br /> to have you here!
            </motion.div>
            <motion.button
              initial={{ display: "none", transform: "translate(0,600px)" }}
              animate={{ display: "block", transform: "translate(0,0px)" }}
              transition={{ delay: 2.7, duration: 1 }}
              className={styles.button}
              onClick={() => navigate("login", { state: { kiosk, partner } })}
            >
              Let’s Go
            </motion.button>

            <motion.img
              initial={{ display: "none", transform: "translate(0,600px)" }}
              animate={{ display: "block", transform: "translate(0,0px)" }}
              transition={{ delay: 2.7, duration: 1 }}
              style={{ marginTop: "15px", marginBottom: 15 }}
              src={gLogo}
              alt="gLogo"
            />
          </>
        ) : (
          <motion.div
            initial={{ transform: "scale(0)" }}
            animate={{ transform: "scale(1)" }}
            transition={{ delay: 2.7, duration: 1 }}
            className={styles.notActive}
          >
            Oops! This kiosk is not open. Please try again a bit later.
          </motion.div>
        )}
      </div>
    </>
  );
};
export default Welcome;
