import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Welcome from "./components/Welcome";
import Login from "./components/Login";
import Otp from "./components/Otp";
import Menu from "./components/Menu";
import MenuNew from "./pages/Menu/Menu"
import Payment from "./components/payment";
import OderPlaced from "./components/OrderPlaced";
import "./App.css";
import * as React from "react";
function App() {
  React.useEffect(() => {
    window.addEventListener('popstate', (e) => {
      window.history.go(1);
    });
  }, [])

  return (
    <Router>
      <Routes>
        <Route path="/:id" element={<Welcome />} />
        <Route path="/:id/login" element={<Login />} />
        <Route path="/:id/login/otp" element={<Otp />} />
        <Route path="/menu" element={<MenuNew />} />
        {/* <Route path="/menu2" element={<MenuNew />} /> */}
        <Route path="/payment" element={<Payment />} />
        <Route path="/orderPlaced" element={<OderPlaced />} />
      </Routes>
    </Router>
  );
}

export default App;
