import styles from "./styles.module.css";
import Header from "./header";
import MenuList from "./menuList";
import CheckOut from "./checkOut";
import { useMenuContext } from "../context/menuContext";
const MenuPage = () => {
  const { paymentPage } = useMenuContext().state;
  return (
    <>
      {!paymentPage && (
        <>
          <div className={styles.container}>
            <Header />
            <MenuList />
          </div>
          <CheckOut />
        </>
      )}
    </>
  );
};
export default MenuPage;
