import { useState } from "react";
import styles from "./styles.module.css";
import { useMenuContext } from "../context/menuContext";
const MenuCard = ({ item, index }) => {
  const { addToCart, removeCart } = useMenuContext();
  const [showDes, setShowDes] = useState(false);
  const showDescription = (des, stock) => {
    return (
      <div className={styles.itemDescription}>
        <span
          className={showDes ? styles.descriptionOpen : styles.descriptionClose}
        >
          {des}
        </span>
        <span
          onClick={() => setShowDes(!showDes)}
          className={stock ? styles.more : styles.ofcMore}
        > 
         {showDes ? "Less" : (des.length >= 96 ? "More":'' )}
        </span>
      </div>
    );
  };
  return (
    <div
      style={{ position: !item.stock ? "relative" : "initial" }}
      key={item.skuName}
    >
      <div className={!item.stock ? styles.ofsContainer : ""}></div>
      <div className={styles.cardContainer}>
        <div
          className={styles.cardImage}
          style={{
            backgroundImage: `url(${item.image})`,
            filter: !item.stock ? "grayscale()" : "none",
            position: !item.stock ? "relative" : "initial",
          }}
        >
          {!item.stock ? (
            <div className={!item.stock ? styles.ofsImage : ""}>
              <div className={styles.ofcBanner}>Out of Stock</div>
            </div>
          ) : null}
        </div>
        <div className={styles.cardDetails}>
          <div className={styles.itemName}>{item.skuName}</div>
          <div className={styles.itemPrice}>&#8377;{item.price}</div>

          <div className={styles.itemSelector}>
            <div
              onClick={() => removeCart(item, index)}
              className={styles.selectorButton}
              disabled={item.quantity <= 0}
            >
              -
            </div>
            <div className={styles.selectorButton}>{item.quantity}</div>
            <div
              onClick={() => addToCart(item, index)}
              className={styles.selectorButton}
            >
              +
            </div>
          </div>
        </div>
      </div>
      {showDescription(item.description, item.stock)}
      <div className={styles.horizontalLine}></div>
    </div>
  );
};
export default MenuCard;
