import moment from "moment";
import { OPERATOR } from "./constant";
import { fetchOne } from "../api";

export const checkKioskIsOpen = async () => {
  try {
    const partnerId = JSON.parse(sessionStorage.getItem("partnerData"));
    const response = await fetchOne(OPERATOR, partnerId._id);
    let data = response.data.data;
    if (response && response.data.data) {
      if (
        data.lastlogin &&
        moment(data.lastlogin).isSame(moment(new Date()), "day") &&
        data.loggedIn
      ) {
        return true;
      } else {
        return false;
      }
    }
  } catch (error) {
    console.error(error);
  }
};

export const logoutUser = (navigate) => {
  sessionStorage.removeItem("partnerData");
  const kioskId = sessionStorage.getItem("kioskId");
  navigate(`/${kioskId}`);
};

export const getTotal = () => {
  let total = 0;
  const data = JSON.parse(sessionStorage.getItem("cartItems"));
  data
    .filter((menu) => menu.quantity > 0)
    .forEach((e) => {
      if (e.quantity > 0) {
        const gst = (e.price * 5) / 100;
        total = total + (e.price - gst) * e.quantity;
      }
    });
  return parseFloat(total.toFixed(2));
};

export const getGst = () => {
  let gst = 0;
  const data = JSON.parse(sessionStorage.getItem("cartItems"));
  data
    .filter((menu) => menu.quantity > 0)
    .forEach((e) => {
      if (e.quantity > 0) {
        const gstforItem = (e.price * 5) / 100;
        gst = gst + gstforItem * e.quantity;
      }
    });
  return parseFloat(gst.toFixed(2));
};

export const getPackingCharges = () => {
  let packingcharge = 0;
  const data = JSON.parse(sessionStorage.getItem("cartItems"));
  data
    .filter((menu) => menu.quantity > 0)
    .forEach((e) => {
      if (e.quantity > 0) {
        const packingChargeForItem = (e.price * 5) / 100;
        packingcharge = Math.round(
          packingcharge + packingChargeForItem * e.quantity
        );
      }
    });
  return parseFloat(packingcharge.toFixed(2));
};

export const getFinalTotal = (isTakeAwayOrder) => {
  let total = getTotal() + getGst();
  if (isTakeAwayOrder) {
    total = total + getPackingCharges();
  }
  return total;
};
