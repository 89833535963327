import { MenuProvider, useMenuContext } from "../../context/menuContext";
import OrderPayment from "../../components/orderPayment";
import MenuPage from "../../components/menuPage";

const MenuNew = () => {
  return (
    <MenuProvider>
      <MenuPage />
      <OrderPayment />
    </MenuProvider>
  );
};
export default MenuNew;
