import { useEffect, useState } from "react";
import logo from "../../assets/logo.png";
import styles from "./styles.module.css";
import Payment from "../payment";
import { useNavigate } from "react-router-dom";
import chef from "../../assets/chef_icon.png";
import clock from "../../assets/clock.png";
import { useLocation } from "react-router-dom";
import {
  fetchSkuById,
  fetchSales,
  fetchAll,
  fetchReportData,
  fetchOne,
  update,
  fetchAvlQty,
  updateQty,
} from "../../api";
import { ORDER, BRAND, DISPATCH, KIOSK, PARTNER } from "../../utils/constant";
import { toast } from "react-toastify";
import moment from "moment";
import { io } from "socket.io-client";
import LoadingSpinner from "../../helper/spinner/spinner";
import { date } from "yup";
import { checkKioskIsOpen, logoutUser } from "../../utils/utils";

const Menu = () => {
  // const socket = io('https://delectable.appswise.tech');
  const [isLoading, setIsLoading] = useState(false);
  const [showTypeContainer, setShowTypeContainer] = useState(false);
  const [isTakeAway, setIsTakeAway] = useState(false);
  const [proceedToPay, setProceedToPay] = useState(false);
  const [showFullProceed, setShowFullProceed] = useState(false);
  const [brand, setBrand] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [kioskItems, setKioskItems] = useState([]);

  let navigate = useNavigate();

  let state = useLocation(),
    { kiosk, order } = state.state;

  useEffect(() => {
    const socket = io("https://delectable.appswise.tech");
    socket.on("connect", () => {
      console.log(socket.id, "socketId")
      if (socket.id) {
        socket.on("fetchUpdatedData", (res) => {
          console.log("socketId called")
          getSalesReports();
          getDispatchData();
        });
      }
    });
  }, []);

  useEffect(() => {
    getDispatchData();
    getBrand();
    getSalesReports();
    if (
      localStorage.hasOwnProperty("items") ||
      localStorage.hasOwnProperty("cartItems")
    ) {
      localStorage.removeItem("items");
      localStorage.removeItem("cartItems");
    }
  }, []);

  useEffect(() => {
    if (!proceedToPay) {
      setIsLoading(true);
      getDispatchData();
      setShowFullProceed(false);
    }
  }, [proceedToPay]);

  const getKioskData = async (dispatched) => {
    setIsLoading(true);
    try {
      const res = await fetchOne(KIOSK, kiosk._id);
      // console.log(res.data, "kioskData");
      if (res && res.data && res.data.data) {
        let data = await getItems(res.data.data.items, dispatched);
        let kioskData = [...data];
        let cartItems = JSON.parse(localStorage.getItem("cartItems"));
        setTimeout(() => {
          if (
            cartItems?.length > 0 &&
            cartItems.filter((e) => e.quantity).length > 0
          ) {
            cartItems.forEach((e) =>
              kioskData.forEach((f, i) => {
                if (e.skuid === f.skuid) {
                  if (e.quantity <= f.stock) {
                    if (e.comboItems && e.comboItems.length > 0) {
                      kioskData[i].quantity = e.quantity;
                      e.comboItems.forEach((g, comboIndex) => {
                        let index = kioskData.findIndex(
                          (h) => h.skuid === g.skuid
                        );
                        if (index !== -1)
                          kioskData[index].virtualStock =
                            kioskData[index].virtualStock -
                            (e.quantity > 0
                              ? e.quantity * e.comboItems[comboIndex].quantity
                              : 0);
                      });
                    } else {
                      kioskData[i].quantity = e.quantity;
                      kioskData[i].virtualStock = f.virtualStock - e.quantity;
                    }
                    // kioskData[i].quantity = e.quantity;
                    // kioskData[i].virtualStock = e.virtualStock
                  } else {
                    kioskData[i].quantity = 0;
                    kioskData[i].virtualStock = f.virtualStock;
                  }
                }
              })
            );
            // console.log(kioskData, "kioskData");
            setKioskItems(kioskData.sort((a, b) => a.price - b.price));
            setShowTypeContainer(
              kioskData.filter((e) => e.quantity > 0).length
            );
            setIsLoading(false);
          } else {
            // console.log(data,"data")
            setKioskItems([...data.sort((a, b) => a.price - b.price)]);
            setShowTypeContainer(
              kioskData.filter((e) => e.quantity > 0).length
            );
            setIsLoading(false);
          }
        }, 500);
        let orderItems = JSON.parse(localStorage.getItem("items"));
        if (!proceedToPay && orderItems && orderItems.length > 0 && data) {
          const items = [...data];
          orderItems.forEach((e) =>
            items.forEach((f, i) => {
              if (e.skuid === f.skuid) {
                if (e.quantity <= f.stock) {
                  if (e.comboItems && e.comboItems.length > 0) {
                    items[i].quantity = e.quantity;
                    e.comboItems.forEach((g, comboIndex) => {
                      let index = items.findIndex((h) => h.skuid === g.skuid);
                      if (index !== -1)
                        items[index].virtualStock =
                          items[index].virtualStock -
                          (e.quantity > 0
                            ? e.quantity * e.comboItems[comboIndex].quantity
                            : 0);
                    });
                  } else {
                    items[i].quantity = e.quantity;
                    items[i].virtualStock = f.virtualStock - e.quantity;
                  }
                } else {
                  items[i].quantity = 0;
                  items[i].virtualStock = f.virtualStock;
                }
              }
            })
          );
          setKioskItems(items.sort((a, b) => a.price - b.price));
          if (items.reduce((a, v) => a + v.quantity, 0) <= 0) {
            setShowTypeContainer(false);
            setIsLoading(false);
          } else {
            setShowTypeContainer(
              kioskItems.filter((e) => e.quantity > 0).length
            );
            setIsLoading(false);
          }
        }
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const getItems = async (itemsArray, dispatched) => {
    let data = [];
    await Promise.all(
      itemsArray.map(async (item) => {
        try {
          let comboArray = [];
          const response = await fetchSkuById(item.skuid);
          if (item.comboItems.length > 0) {
            item.comboItems.forEach((e) => {
              if (
                itemsArray.filter((f) => f.skuid === e.skuid)[0].quantity >=
                e.quantity
              ) {
                comboArray.push(
                  Math.trunc(
                    itemsArray.filter((f) => f.skuid === e.skuid)[0].quantity /
                    e.quantity
                  )
                );
              } else {
                comboArray.push(0);
                return;
              }
            });
            let obj = {
              ...response.data.data,
              quantity: 0,
              stock: dispatched ? Math.min(...comboArray) : 0,
              virtualStock: dispatched ? Math.min(...comboArray) : 0,
              comboItems: item.comboItems,
              isComboItems: true,
              skuid: item.skuid,
              viewDescription: false,
            };
            data.push(obj);
          } else {
            let obj = {
              ...response.data.data,
              quantity: 0,
              stock: dispatched ? item.quantity : 0,
              virtualStock: dispatched ? item.quantity : 0,
              isComboItems: false,
              skuid: item.skuid,
              viewDescription: false,
            };
            data.push(obj);
          }
        } catch (error) {
          console.log("error" + error);
        }
      })
    );
    return data;
  };

  const getDispatchData = async () => {
    try {
      const response = await fetchReportData(
        DISPATCH,
        "puid",
        kiosk.puid,
        "date",
        moment(new Date()).format("YYYY-MM-DD"),
        moment(new Date()).add(1, "days").format("YYYY-MM-DD")
      );
      if (response.data) {
        let data = response.data.data;
        if (data && data.length) {
          getKioskData(true);
        } else {
          getKioskData(false);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const getBrand = async () => {
    const res = await fetchAll(BRAND);
    if (res.data) {
      setBrand(res.data.data);
    }
  };

  const getBrandName = () => {
    return brand && brand.length > 0 ? brand[0].name : "";
  };

  const storeItems = () => {
    if (kioskItems && kioskItems.length > 0) {
      localStorage.removeItem("items");
      localStorage.setItem("items", JSON.stringify(getSelectedItems()));
      checkAvlQuantity();
      // localStorage.removeItem('cartItems')
    }
  };

  const addItems = (i) => {
    const data = [...kioskItems];

    // console.log(data[i])
    let items = [],
      citems = [],
      avlQty = kioskItems,
      cartItems = data[i].comboItems;

    if (data[i].comboItems) {
      data[i].isCombo = true;
      avlQty.forEach((e) =>
        cartItems.forEach((f) => {
          if (e.skuid == f.skuid) {
            f.skuName = e.skuName;
            // console.log(f)
            if (e.virtualStock >= 0 && e.virtualStock >= f.quantity) {
              citems.push(f);
              items.push(e.virtualStock);
              if (cartItems.length == items.length) {
                data[i].quantity = data[i].quantity + 1;
                let finData = [];
                citems.forEach((c) => {
                  let dataI = data.findIndex((d) => d.skuid == c.skuid);
                  if (dataI !== -1) {
                    data[dataI].virtualStock =
                      data[dataI].virtualStock - c.quantity;
                  }
                });
              }
            } else {
              if (e.virtualStock > 0) {
                setIsLoading(false);
                toast.error(
                  `Only ${e.virtualStock} ${f.skuName} available choose available Quantity`
                );
              } else {
                // data[i].quantity = data[i].quantity - 1;
                setIsLoading(false);
                toast.error(
                  `${f.skuName} is out of stock, choose available items`
                );
              }
            }
          }
        })
      );
    } else {
      data[i].isCombo = false;

      if (data[i].virtualStock === 0) {
        toast.error(`${data[i].skuName} is out of stock`);
        // data[i].quantity = data[i].quantity - 1;
      } else if (data[i].virtualStock > 0) {
        data[i].quantity = data[i].quantity + 1;
        data[i].virtualStock = data[i].virtualStock - 1;
        setKioskItems(data);
        localStorage.removeItem("cartItems");
        localStorage.setItem("cartItems", JSON.stringify(kioskItems));
        setShowTypeContainer(kioskItems.filter((e) => e.quantity > 0).length);
      }
    }

    // if (data[i].quantity > data[i].stock) {
    //   data[i].quantity = data[i].quantity - 1;
    //   toast.error(`${data[i].skuName} is out of stock`)
    // } else {
    setKioskItems(data);
    localStorage.removeItem("cartItems");
    localStorage.setItem("cartItems", JSON.stringify(kioskItems));
    setShowTypeContainer(kioskItems.filter((e) => e.quantity > 0).length);
  };
  // };

  const removeItems = (i) => {
    const data = [...kioskItems];
    data[i].quantity = data[i].quantity - 1 < 0 ? 0 : data[i].quantity - 1;
    if (data[i].comboItems) {
      if (data[i].virtualStock < data[i].stock)
        data[i].virtualStock = data[i].virtualStock + 1;
      data[i].comboItems.forEach((e, comboIndex) => {
        let index = data.findIndex((f) => f.skuid === e.skuid);
        if (data[index].virtualStock < data[index].stock) {
          data[index].virtualStock =
            data[index].virtualStock + data[i].comboItems[comboIndex].quantity;
        }
      });
    } else {
      if (data[i].virtualStock < data[i].stock)
        data[i].virtualStock =
          data[i].virtualStock < 0 ? 0 : data[i].virtualStock + 1;
    }
    setKioskItems(data);
    localStorage.removeItem("cartItems");
    localStorage.setItem("cartItems", JSON.stringify(kioskItems));
    setShowTypeContainer(kioskItems.filter((e) => e.quantity > 0).length);
  };

  const getItemsCount = () => {
    return kioskItems.reduce((a, v) => a + v.quantity, 0);
  };
  const getTotal = () => {
    let total = 0;
    kioskItems.forEach((e) => {
      if (e.quantity > 0) {
        total = total + e.price * e.quantity;
      }
    });
    return total;
  };

  const getSelectedItems = () => {
    return kioskItems.filter((e) => e.quantity > 0);
  };

  const toggleShowDescription = (index) => {
    const data = [...kioskItems];
    data[index].viewDescription = !data[index].viewDescription;
    setKioskItems(data);
  };

  const getSalesReports = async () => {
    try {
      const response = await fetchSales(
        ORDER,
        "kid",
        kiosk.kid,
        "date",
        moment(new Date()).format("YYYY-MM-DD"),
        moment(new Date()).add(1, "days").format("YYYY-MM-DD")
      );
      if (response.data.data) {
        let data = response.data.data
          .filter(
            (f) =>
              f.deleted === false &&
              f.status != "Completed" &&
              f.status != "Pending"
          )
          .filter((f) => f.items.length);
        // console.log(data, "data");
        setOrderList(data);
      }
    } catch (error) {
      console.log(error);
      // toast.error(error.response.data.message);
    }
  };

  const getOrderLength = () => {
    return orderList && orderList.length ? orderList.length : 0;
  };

  const getWaitingTime = (data) => {
    let waitTime = 0;
    data.forEach((element) => {
      if (element.items.length) {
        waitTime += parseInt(element.items.reduce((a, v) => v.quantity + a, 0));
      }
    });

    return waitTime;
  };

  const showDescription = (des, view, stock, index) => {
    return (
      <div className={styles.itemDescription}>
        <span
          className={view ? styles.descriptionOpen : styles.descriptionClose}
        >
          {des}
        </span>
        <span
          onClick={() => toggleShowDescription(index)}
          className={stock ? styles.more : styles.ofcMore}
        >
          {view ? "Less" : des.length > 97 ? "More" : ""}
        </span>
      </div>
    );
  };

  const checkAvlQuantity = async () => {
    setIsLoading(true);
    try {
      if (await checkKioskIsOpen()) {
        if (kioskItems.length > 0) {
          let cartItems = JSON.parse(localStorage.getItem("cartItems"));
          let items = [],
            avlQty = [];
          const response = await fetchAvlQty(KIOSK, "kid", kiosk.kid);
          if (response.data) {
            avlQty = response.data.data[0].items;
          }
          avlQty.forEach((e) =>
            cartItems.forEach((f) => {
              if (e.skuid == f.skuid) {
                // console.log(f)
                f.isCombo && f.comboItems.length > 0
                  ? (e.quantity = f.stock)
                  : (e.quantity = e.quantity);
                if (e.quantity >= 0 && e.quantity >= f.quantity) {
                  items.push(e.quantity);
                  if (cartItems.length == items.length) {
                    let timeout = Math.floor(
                      Math.random() * (4000 - 1000 + 1) + 1000
                    );
                    setTimeout(() => {
                      blockKioskItems(avlQty, cartItems, kiosk);
                    }, timeout);
                  }
                } else {
                  if (e.quantity > 0) {
                    setIsLoading(false);
                    toast.error(
                      `only ${e.quantity} ${f.skuName} available choose available Quantity`
                    );
                  } else {
                    setIsLoading(false);
                    toast.error(
                      `${f.skuName} is out of stock, choose available items`
                    );
                  }
                }
              }
            })
          );
        }
      } else {
        setIsLoading(false);
        toast.error(`Kiosk got closed!`);
        logoutUser(navigate);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const checkQty = async () => {
    setIsLoading(true);
    try {
      if (await checkKioskIsOpen()) {
        if (kioskItems.length > 0) {
          let cartItems = JSON.parse(localStorage.getItem("cartItems"));
          let items = [],
            avlQty = [];
          const response = await fetchAvlQty(KIOSK, "kid", kiosk.kid);
          if (response.data) {
            avlQty = response.data.data[0].items;
          }
          avlQty.forEach((e) =>
            cartItems.forEach((f) => {
              if (e.skuid == f.skuid) {
                f.isCombo && f.comboItems.length > 0
                  ? (e.quantity = f.stock)
                  : (e.quantity = e.quantity);
                if (e.quantity >= 0 && e.quantity >= f.quantity) {
                  items.push(e.quantity);
                  if (cartItems.length == items.length) {
                    setShowFullProceed(true);
                    setIsLoading(false);
                  }
                } else {
                  if (e.quantity > 0) {
                    setIsLoading(false);
                    toast.error(
                      `only ${e.quantity} ${f.skuName} available choose available Quantity`
                    );
                  } else {
                    setIsLoading(false);
                    toast.error(
                      `${f.skuName} is out of stock, choose available items`
                    );
                  }
                }
              }
            })
          );
        }
      } else {
        setIsLoading(false);
        toast.error(`Kiosk got closed!`);
        logoutUser(navigate);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const blockKioskItems = async (kioskData, items, kisok) => {
    // const updatedKioskData = kioskData.map(e => {
    //   let ordersItems = items.filter(f => {
    //     if (f.skuid === e.skuid) {
    //       return f
    //     }
    //   })
    //   let quantity = e.quantity - ordersItems.reduce((a, r) => a + r.quantity, 0)
    //   return {
    //     ...e,
    //     quantity: quantity
    //   }
    // })

    try {
      let items = JSON.parse(localStorage.getItem("items"));
      let itemsArray = [];
      items.forEach((f) => {
        if (f.isComboItems && f.comboItems.length > 0) {
          f.comboItems.forEach((e) => {
            itemsArray.push({
              ...e,
              quantity: e.quantity * f.quantity,
            });
          });
        } else {
          itemsArray.push(f);
        }
      });
      let tempArray = [];
      itemsArray.forEach((item) => {
        const obj = tempArray.find((o) => o.skuid === item.skuid);
        if (obj) {
          obj.quantity = obj.quantity + item.quantity;
        } else {
          tempArray.push(item);
        }
      });
      const kioskResponse = await updateQty(KIOSK, kisok._id, {
        kid: kisok.kid,
        cartItems: tempArray,
      });
      if (kioskResponse.data.statusCode == 200) {
        // socket.emit('orderPlaced')
        let payload = {
          items: JSON.parse(localStorage.getItem("items")),
          status: "Order Processing",
          kid: kiosk.kid,
          deleted: false,
        };
        const response = await update(ORDER, order._id, payload);
        if (response.data.statusCode == 200) {
          setIsLoading(false);
          setProceedToPay(true);
          // localStorage.removeItem('cartItems')
        }
        setShowFullProceed(true);
        setIsLoading(false);
      } else {
        toast.error(kioskResponse.data.message);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setIsLoading(false);
    }
  };

  const menu = !proceedToPay ? (
    <>
      {/* {isLoading ? <LoadingSpinner /> : renderUser} */}
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <img style={{ width: "83px" }} src={logo} alt="logo" />
        </div>
        <div className={styles.welcomeMessage}>Order Here!</div>
        <div className={styles.statusCard}>
          <div className={styles.list}>
            <div className={styles.row}>
              <img alt="icon" className={styles.icon} src={chef} />
              <div className={styles.label}>Total orders in queue</div>
            </div>
            <div className={styles.value}>
              {orderList && orderList.length > 0 ? orderList.length : 0}
            </div>
          </div>
          <div className={styles.list}>
            <div className={styles.row}>
              <img alt="icon" className={styles.icon} src={clock} />
              <div className={styles.label}>Approximate waiting time</div>
            </div>
            <div className={styles.value}>
              {orderList && orderList.length > 0
                ? getWaitingTime(orderList)
                : 0}{" "}
              Min
            </div>
          </div>
        </div>
        <div className={styles.menuContainer}>
          {kioskItems.map((item, index) => (
            <div
              style={{ position: !item.stock ? "relative" : "initial" }}
              key={item.skuName}
            >
              <div className={!item.stock ? styles.ofsContainer : ""}></div>
              <div className={styles.cardContainer}>
                <div
                  className={styles.cardImage}
                  style={{
                    backgroundImage: `url(${item.image})`,
                    filter: !item.stock ? "grayscale()" : "none",
                    position: !item.stock ? "relative" : "initial",
                  }}
                >
                  {!item.stock ? (
                    <div className={!item.stock ? styles.ofsImage : ""}>
                      <div className={styles.ofcBanner}>Out of Stock</div>
                    </div>
                  ) : null}
                </div>
                <div className={styles.cardDetails}>
                  <div className={styles.itemName}>{item.skuName}</div>
                  <div className={styles.itemPrice}>&#8377;{item.price}</div>

                  <div className={styles.itemSelector}>
                    <div
                      onClick={() => removeItems(index)}
                      className={styles.selectorButton}
                      disabled={item.quantity <= 0}
                    >
                      -
                    </div>
                    <div className={styles.selectorButton}>{item.quantity}</div>
                    <div
                      onClick={() => addItems(index)}
                      className={styles.selectorButton}
                    >
                      +
                    </div>
                  </div>
                </div>
              </div>
              {showDescription(
                item.description,
                item.viewDescription,
                item.stock,
                index
              )}
              <div className={styles.horizontalLine}></div>
            </div>
          ))}
        </div>
      </div>
      {showTypeContainer ? (
        showFullProceed ? (
          <div className={styles.checkoutContainer}>
            <div
              onClick={() => setShowFullProceed(false)}
              className={styles.close}
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </div>
            <div className={styles.totalDetails}>
              <div className={styles.totalItem}>
                Total | {getItemsCount()} items
              </div>
              <div className={styles.totalPrice}>&#8377;{getTotal()}</div>
            </div>
            <div className={styles.menuType}>
              <div
                onClick={() => setIsTakeAway(false)}
                className={`form-check ${styles.checkboxContainer}`}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={!isTakeAway}
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Having it here
                </label>
              </div>
              <div
                onClick={() => setIsTakeAway(true)}
                className={`form-check ${styles.checkboxContainer}`}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={isTakeAway}
                />
                <label className="form-check-label" htmlFor="flexCheckChecked">
                  Take away
                </label>
              </div>
            </div>
            <button
              disabled={isLoading}
              onClick={() => {
                storeItems();
              }}
              className={styles.button}
            >
              Proceed to Checkout
            </button>
          </div>
        ) : (
          <div className={styles.checkoutContainerLess}>
            <div className={styles.totalDetailsLess}>
              <div className={styles.totalPrice}>&#8377;{getTotal()}</div>
              <div style={{ paddingTop: "10px" }} className={styles.totalItem}>
                Total | {getItemsCount()} items
              </div>
            </div>

            <button
              disabled={isLoading}
              style={{ width: "44%" }}
              onClick={() => checkQty()}
              className={styles.button}
            >
              Continue
            </button>
          </div>
        )
      ) : null}
    </>
  ) : (
    <Payment
      data={JSON.parse(localStorage.getItem("items"))}
      cancel={setProceedToPay}
      order={order}
      brand={getBrandName()}
      ordersTotal={getOrderLength()}
      takeaway={isTakeAway}
      kioskId={kiosk.kid}
    />
  );

  return (
    <>
      {menu}
      {isLoading && <LoadingSpinner />}
    </>
  );
};
export default Menu;
