import { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  create,
  fetchOne,
  fetchReportData,
  fetchSales,
  fetchSkuById,
  update,
  updateQty,
} from "../api";
import { DISPATCH, KIOSK, ORDER } from "../utils/constant";
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import moment from "moment";
import LoadingSpinner from "../helper/spinner/spinner";
import {
  checkKioskIsOpen,
  getFinalTotal,
  getGst,
  getTotal,
  logoutUser,
} from "../utils/utils";

export const MenuContext = createContext();
export const useMenuContext = () => useContext(MenuContext);

export const MenuProvider = ({ children }) => {
  const kioskData = JSON.parse(sessionStorage.getItem("kioskData"));
  const order = JSON.parse(sessionStorage.getItem("order"));
  const navigate = useNavigate();
  const [state, setState] = useState({
    loading: false,
    orders: [],
    dispatchData: [],
    menuItems: [],
    showCheckoutPage: false,
    isTakeAwayOrder: false,
    paymentPage: false,
    paymentType: "cash",
  });

  useEffect(() => {
    const newSocket = io("https://delectable.appswise.tech");
    newSocket.on("connect", () => {
      console.log(newSocket.id); // x8WIv7-mJelg7on_ALbx
      if (newSocket.id) {
        newSocket.on("fetchUpdatedData", (res) => {
          let paypage = sessionStorage.getItem("payment");
          // console.log(paypage,typeof paypage);
          if (paypage == null) {
            console.log("socketId called");
            updateState(JSON.parse(sessionStorage.getItem("state")));
            fetchDispatchData();
            fetchOrders();
          }
        });
      }
    });
    newSocket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`);
    });
  }, []);

  useEffect(() => {
    fetchOrders();
    fetchDispatchData();
  }, []);

  const updateLatestData = async () => {
    let res = await fetchOne(KIOSK, kioskData._id);
    if (res && res.data && res.data.data) {
      let latestData = await getItems(res.data.data.items, true);
      let currentItems = JSON.parse(sessionStorage.getItem("cartItems"));
      let updatedItems = [];
      // console.log(latestData, currentItems);
      currentItems.forEach((item) => {
        let filteredItem = latestData.filter(
          (latest) => latest.skuid === item.skuid
        )[0];
        // console.log(filteredItem, "filteredItem");
        updatedItems.push({
          ...item,
          virtualStock: filteredItem.virtualStock,
          stock: filteredItem.virtualStock,
          quantity:
            filteredItem.virtualStock < item.quantity
              ? filteredItem.virtualStock
              : item.quantity,
        });
      });
      if (state.paymentPage) {
        updateState({ paymentPage: true });
      }
      if (updatedItems.length) {
        updateCart(updatedItems);
      } else {
        updateCart(latestData);
      }
      // updateState({
      //   paymentPage: JSON.parse(sessionStorage.getItem("payment")),
      // });
    }
  };

  const updateState = (newState) => {
    setState({ ...state, ...newState });
    sessionStorage.setItem("state", JSON.stringify({ ...state, ...newState }));
  };

  const fetchDispatchData = async () => {
    try {
      updateState({ loading: true });
      const dispatchResponse = await fetchReportData(
        DISPATCH,
        "puid",
        kioskData.puid,
        "date",
        moment(new Date()).format("YYYY-MM-DD"),
        moment(new Date()).add(1, "days").format("YYYY-MM-DD")
      );
      if (dispatchResponse.data) {
        let data = dispatchResponse.data.data;
        if (data && data.length) {
          updateState({ dispatchData: data });
          getKioskData(true);
        } else {
          getKioskData(false);
          updateState({ loading: false });
        }
      }
    } catch (error) {
      updateState({ loading: false });
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const fetchOrders = async () => {
    try {
      updateState({ loading: true });
      const response = await fetchSales(
        ORDER,
        "kid",
        kioskData.kid,
        "date",
        moment(new Date()).format("YYYY-MM-DD"),
        moment(new Date()).add(1, "days").format("YYYY-MM-DD")
      );
      if (response.data.data) {
        let data = response.data.data
          .filter(
            (f) =>
              f.deleted === false &&
              f.status != "Completed" &&
              f.status != "Pending"
          )
          .filter((f) => f.items.length);
        // console.log(data, "orders");
        sessionStorage.setItem("orders", JSON.stringify(data));
        // updateState({ orders: data });
      }
    } catch (error) {
      console.log(error);
      // toast.error(error.response.data.message);
    }
  };

  const getKioskData = async (dispatched) => {
    try {
      updateState({ loading: true });
      const res = await fetchOne(KIOSK, kioskData._id);
      if (res && res.data && res.data.data) {
        let data = await getItems(res.data.data.items, dispatched);
        if (data) {
          updateState({ menuItems: data, loading: false });
        }
      }
    } catch (error) {}
  };

  const updateCart = (items) => {
    updateState({ menuItems: items });
    sessionStorage.setItem("cartItems", JSON.stringify(items));
  };

  const addToCart = (item, index) => {
    //     if (item.virtualStock >= item.quantity + 1) {
    //       const updatesItems = state.menuItems;
    // updatesItems[index].quantity = updatesItems[index].quantity + 1;
    //       updateCart(updatesItems);
    // } else {
    //       toast.error(
    //         `Only ${item.virtualStock} ${item.skuName} available choose available Quantity`
    //       );
    //     }
    const data = [...state.menuItems];

    // console.log(data[i])
    let items = [],
      citems = [],
      avlQty = state.menuItems,
      cartItems = item.comboItems;

    if (item.comboItems) {
      item.isCombo = true;
      avlQty.forEach((e) =>
        cartItems.forEach((f) => {
          if (e.skuid == f.skuid) {
            f.skuName = e.skuName;
            // console.log(f)
            if (e.virtualStock >= 0 && e.virtualStock >= f.quantity) {
              // console.log(f);
              citems.push(f);
              items.push(e.virtualStock);
              if (cartItems.length == items.length) {
                item.quantity = item.quantity + 1;
                let finData = [];
                // console.log(citems);
                citems.forEach((c) => {
                  let dataI = data.findIndex((d) => d.skuid == c.skuid);
                  if (dataI !== -1) {
                    data[dataI].virtualStock =
                      data[dataI].virtualStock - c.quantity;
                  }
                });
                // console.log(data);
              }
            } else {
              if (e.virtualStock > 0) {
                // setIsLoading(false);
                toast.error(
                  `Only ${e.virtualStock} ${f.skuName} available choose available Quantity`
                );
              } else {
                // item.quantity = item.quantity - 1;
                // setIsLoading(false);
                toast.error(
                  `${f.skuName} is out of stock, choose available items`
                );
              }
            }
          }
        })
      );

      updateCart(data);
    } else {
      item.isCombo = false;

      if (item.virtualStock === 0) {
        toast.error(`${item.skuName} is out of stock`);
        // item.quantity = item.quantity - 1;
      } else if (item.virtualStock > 0) {
        // console.log(item);
        item.quantity = item.quantity + 1;
        item.virtualStock = item.virtualStock - 1;
        updateCart(data);
      }
    }
  };

  const removeCart = (item, index) => {
    // if (item.quantity - 1 >= 0) {
    //   const updatesItems = state.menuItems;
    //   updatesItems[index].quantity = updatesItems[index].quantity - 1;
    //   updateCart(updatesItems);
    // }
    const data = [...state.menuItems];
    item.quantity = item.quantity - 1 < 0 ? 0 : item.quantity - 1;
    if (item.comboItems) {
      if (item.virtualStock < item.stock)
        item.virtualStock = item.virtualStock + 1;
      item.comboItems.forEach((e, comboIndex) => {
        let index = data.findIndex((f) => f.skuid === e.skuid);
        if (data[index].virtualStock < data[index].stock) {
          data[index].virtualStock =
            data[index].virtualStock + item.comboItems[comboIndex].quantity;
        }
      });
    } else {
      if (item.virtualStock < item.stock)
        item.virtualStock = item.virtualStock < 0 ? 0 : item.virtualStock + 1;
    }
    updateCart(data);
  };

  const getItems = async (itemsArray, dispatched) => {
    let data = [];
    let skuData;
    let existingSkus = JSON.parse(sessionStorage.getItem("skuItems")) || [];
    await Promise.all(
      itemsArray.map(async (item) => {
        try {
          let comboArray = [];
          if (
            existingSkus &&
            existingSkus.map((sku) => sku._id).includes(item.skuid)
          ) {
            skuData = existingSkus.filter((sku) => sku._id === item.skuid)[0];
          } else {
            const response = await fetchSkuById(item.skuid);
            if (response && response.data.data) {
              skuData = response.data.data;
              existingSkus.push(skuData);
              sessionStorage.setItem("skuItems", JSON.stringify(existingSkus));
            }
          }
          if (item.comboItems.length > 0) {
            item.comboItems.forEach((e) => {
              if (
                itemsArray.filter((f) => f.skuid === e.skuid)[0].quantity >=
                e.quantity
              ) {
                comboArray.push(
                  Math.trunc(
                    itemsArray.filter((f) => f.skuid === e.skuid)[0].quantity /
                      e.quantity
                  )
                );
              } else {
                comboArray.push(0);
                return;
              }
            });
            let obj = {
              ...skuData,
              quantity: 0,
              stock: dispatched ? Math.min(...comboArray) : 0,
              virtualStock: dispatched ? Math.min(...comboArray) : 0,
              comboItems: item.comboItems,
              isComboItems: true,
              skuid: item.skuid,
              viewDescription: false,
            };
            data.push(obj);
          } else {
            let obj = {
              ...skuData,
              quantity: 0,
              stock: dispatched ? item.quantity : 0,
              virtualStock: dispatched ? item.quantity : 0,
              isComboItems: false,
              skuid: item.skuid,
              viewDescription: false,
            };
            data.push(obj);
          }
        } catch (error) {
          console.log("error" + error);
        }
      })
    );
    return data;
  };

  const getOrder = async () => {
    try {
      const response = await fetchOne(ORDER, order._id);
      if (response.data) {
        sessionStorage.setItem("order", JSON.stringify(response.data.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const blockMenuItems = async () => {
    let items = state.menuItems.filter((menu) => menu.quantity > 0);
    let itemsArray = [];
    items.forEach((f) => {
      if (f.isComboItems && f.comboItems.length > 0) {
        f.comboItems.forEach((e) => {
          itemsArray.push({
            ...e,
            quantity: e.quantity * f.quantity,
          });
        });
      } else {
        itemsArray.push(f);
      }
    });
    let tempArray = [];
    itemsArray.forEach((item) => {
      const obj = tempArray.find((o) => o.skuid === item.skuid);
      if (obj) {
        obj.quantity = obj.quantity + item.quantity;
      } else {
        tempArray.push(item);
      }
    });
    const role = sessionStorage.getItem("userType");
    let response = null;
    updateState({ loading: true });
    const kioskResponse = await updateQty(KIOSK, kioskData._id, {
      kid: kioskData.kid,
      cartItems: tempArray,
      // cartItems: state.menuItems.filter((menu) => menu.quantity > 0),
    });
    if (kioskResponse && kioskResponse.data.statusCode == 200) {
      let payload = {
        items: state.menuItems
          .filter((menu) => menu.quantity > 0)
          .map((e) => ({ ...e, isCombo: e.isComboItems })),
        status: "Order Processing",
        kid: kioskData.kid,
        deleted: false,
      };
      if (role == "operator") {
        const operator = JSON.parse(sessionStorage.getItem("partnerData"));
        const kiosk = JSON.parse(sessionStorage.getItem("kioskData"));
        payload = {
          ...payload,
          mobile: operator.phone,
          puid: kiosk.puid,
          brand: "Nippi Kodi",
          takeaway: state.isTakeAwayOrder,
          date: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss") + ".000Z",
          token: "",
        };
        response = await create(ORDER, payload);
        sessionStorage.setItem("order", JSON.stringify(response.data.data));
      } else {
        response = await update(ORDER, order._id, payload);
      }

      if (response.data.statusCode == 200) {
        getOrder();
        updateState({ paymentPage: "true", loading: false });
      } else {
        updateState({ loading: false });
      }
    } else {
      updateState({ loading: false });
    }
  };

  const checkItems = async () => {
    try {
      updateState({ loading: true });
      if (await checkKioskIsOpen()) {
        const res = await fetchOne(KIOSK, kioskData._id);
        let error = false;
        if (res && res.data && res.data.data) {
          let data = await getItems(res.data.data.items, true);
          const currentItems = JSON.parse(sessionStorage.getItem("cartItems"));
          currentItems.forEach((e, index) => {
            if (e.quantity > 0) {
              let filteredItem = data.filter((item) => item.skuid === e.skuid);
              if (filteredItem && filteredItem.length) {
                if (filteredItem[0].virtualStock < e.quantity) {
                  toast.error(
                    `Only ${filteredItem[0].virtualStock} ${filteredItem[0].skuName} available choose available Quantity`
                  );
                  error = true;
                  updateLatestData();
                  return;
                }
              }
            }
          });
          updateState({ loading: false });
          return error;
        }
      }
      logoutUser(navigate);
    } catch (error) {
      updateState({ loading: false });
    }
  };

  return (
    <MenuContext.Provider
      value={{
        state,
        addToCart,
        removeCart,
        updateState,
        blockMenuItems,
        checkItems,
        updateLatestData,
      }}
    >
      {children}
      {state.loading && <LoadingSpinner />}
    </MenuContext.Provider>
  );
};
