import styles from "./styles.module.css";
import logo from "../../assets/logo.png";
import gLogo from "../../assets/g-logo.png";
import { useNavigate, useLocation } from "react-router-dom";
import { submitOtp, generateOtp, operatorlogin } from "../../api";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

const Otp = () => {
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(30)
  useEffect(() => {
    const interval = setInterval(() => {
      if (count > 0) {
        setCount(count - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [count]);
  let state = useLocation(),
    [otp, setOtp] = useState(),
    { kiosk, partner, number, userType } = state.state,
    [order, setOrder] = useState(),
    navigate = useNavigate(),
    handleNumberChange = (event) => {
      setOtp(event.target.value);
    },
    validate = () => {
      const otpRegex = new RegExp(/^[0-9]/);
      if (!otp || !otpRegex.test(otp)) {
        return true;
      }
    },
    submitNumber = async () => {
      setLoading(true);
      if (userType == 'operator') {
        try {
          let response = await operatorlogin(number, otp, 'customer');

          if (response.data.statusCode === 200) {
            setOrder(response.data.data);
            toast.success('Authenticated Successfully!');
            navigate("/menu", {
              state: { kiosk, partner, order: response.data.data, userType },
            });
            // sessionStorage.setItem('order', JSON.stringify(response.data.data))
            sessionStorage.setItem('userType', userType)
            setLoading(false);
          } else {
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
          toast.error('OTP is Invalid!')
          console.log(error, "error");
        }
      } else if (userType == 'user') {
        try {
          let response = await submitOtp(number, kiosk.kid, otp);

          if (response.data.statusCode === 200) {
            setOrder(response.data.data);
            toast.success('Authenticated Successfully!');
            navigate("/menu", {
              state: { kiosk, partner, order: response.data.data, userType },
            });
            sessionStorage.setItem('order', JSON.stringify(response.data.data))
            setLoading(false);
          } else {
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
          toast.error('OTP is Invalid!')
          console.log(error, "error");
        }
      }

    }

  const resendOtp = async () => {
    setLoading(true)
    let response = await generateOtp(number, kiosk.kid);
    if (response.data.statusCode === 200) {
      setLoading(false)
      toast.success('OTP sent successfully!');
    }
  };

  // let resendOtp = async () => {
  //   let response = await generateOtp(number, kiosk.kid);
  //   if (response.data.statusCode === 200) {
  //     toast.success(response.data.message);
  //   }
  // };
  return (
    <>
      <div className="background"></div>
      <div className={styles.loginContainer}>
        <img src={logo} alt="logo" />
        <div className={styles.welcomeMessage}>
          Welcome <br />
          <div className={styles.caption}>
            <span>Outlet ID {kiosk.kid}</span>
          </div>
        </div>
        <div className={styles.sub_caption}>
          <span>Please authenticate your mobile number to start ordering</span>
        </div>
        {
          userType == 'operator' ? <div>
            <input
              className={styles.input}
              type="password"
              placeholder="Enter Password"
              onChange={handleNumberChange}
            />
          </div> :
            <div><div>
              <input
                className={styles.input}
                placeholder="Enter OTP"
                onChange={handleNumberChange}
              />
            </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: 305 }}>
                {count ? <span className={styles.count}>Remaining Time {count} sec</span> : <span></span>}
                <span onClick={() => {
                  if (count === 0) {
                    resendOtp()
                    setCount(30)
                  }
                }} className={count === 0 ? styles.resendOtpActive : styles.resendOtpInActive}>Resend OTP </span>
              </div>
            </div>
        }
        <button
          disabled={userType == 'user' ? validate() : '' || loading}
          className={styles.button}
          onClick={() => submitNumber()}
        >
          {loading ? (
            <span>
              <span className="fa fa-gear"></span>
              <span style={{ marginLeft: 10 }}>Authenticate</span>
            </span>
          ) : (
            <span> Authenticate</span>
          )}
        </button>
         {/* <button onClick={() => resendOtp()} className={styles.resendBtn}>
          Resend OTP
        </button> */}
        <img style={{ marginTop: "15px" }} src={gLogo} alt="gLogo" />
      </div>
    </>
  );
};
export default Otp;
